import { Observable } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	map,
	switchMap,
	take,
} from 'rxjs/operators';

import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import { ApiInjector } from '@api/api.module';
import { ValidationResultResponse } from '@api/models/validation-result.response';
import { ValidatorsService } from '@api/services/validators.service';
import { PlcValidationErrorType } from '@components/models/foms.models';

const GAME_EXISTS_ERROR = { [PlcValidationErrorType.gameExists]: true };
const INPUT_DEBOUNCE_TIME = 500;

export class PlcGameAsyncValidators {
	/**
	 * A http call is performed to validationService to fetch if control´s value already exists in the database
	 * @param validationService ValidationService from ```@core/services``` to perform the http call
	 * @returns ```{ "gameExists": true }``` if validation is incorrect
	 */
	public static nameExists(): AsyncValidatorFn {
		return (
			control: AbstractControl,
		): Observable<ValidationErrors | null> => {
			const validationService =
				ApiInjector.instance.get(ValidatorsService);

			return control.valueChanges.pipe(
				distinctUntilChanged(),
				debounceTime(INPUT_DEBOUNCE_TIME),
				take(1),
				switchMap((value) =>
					validationService
						.validateGame({ name: value })
						.pipe(
							map(({ valid }: ValidationResultResponse) =>
								!valid ? GAME_EXISTS_ERROR : null,
							),
						),
				),
			);
		};
	}
}
