import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { GameType, PlayersAmount } from '@api/models/enums';
import { GameConstants } from '@api/models/game.constants';
import { NewGameFormTranslations } from '@bussiness/i18n/games.i18n';
import {
	PlcTypedFormControl,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { PlcForm } from '@core/interfaces/form.interface';
import { PlcTranslatable } from '@core/interfaces/translatable.interface';
import { PlcCommonValidators } from '@core/validators/common.validators';
import { PlcGameAsyncValidators } from '@core/validators/game.validators';

export class NewGameFormValues {
	public name: string;
	public playerOptions: PlayersAmount;
	public type: GameType;
	public password: string;
}

@Injectable()
export class NewGameForms
	implements PlcForm, PlcTranslatable<NewGameFormTranslations>
{
	public i18n: NewGameFormTranslations;

	public readonly nameControl: PlcTypedFormControl<string>;
	public readonly playerOptionsControl: PlcTypedFormControl<PlayersAmount[]>;
	public readonly typeControl: PlcTypedFormControl<GameType[]>;
	public readonly passwordControl: PlcTypedFormControl<string>;
	public readonly repeatPasswordControl: PlcTypedFormControl<string>;

	public get nameFieldStatusChanged$(): Observable<void> {
		return this._nameFieldStatusChanged$.asObservable();
	}

	public get playerOptionsValue(): PlayersAmount {
		return this.playerOptionsControl.value[0];
	}

	public get typePrivateSelected(): boolean {
		return this.typeControl.value.includes(GameType.Private);
	}

	public get nameIsValid(): boolean {
		return this.nameControl.valid;
	}

	public get playerOptionsIsValid(): boolean {
		return this.playerOptionsControl.valid;
	}

	public get value(): NewGameFormValues {
		const { name, playerOptions, type, password } = this._form.value;
		return {
			name,
			playerOptions: +playerOptions[0],
			type: type[0] as GameType,
			password,
		};
	}

	public get isValid(): boolean {
		return this._form.valid;
	}

	private _form: UntypedFormGroup;
	private _nameFieldStatusChanged$: Subject<void> = new Subject();

	constructor() {
		this.setTranslations();

		this.nameControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(GameConstants.nameMinLen),
				Validators.maxLength(GameConstants.nameMaxLen),
			],
			asyncValidators: [PlcGameAsyncValidators.nameExists()],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.nameRequired,
					interpolatedParams: { name: this.i18n.name },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.nameMinLen,
					interpolatedParams: {
						name: this.i18n.name,
						min: GameConstants.nameMinLen,
					},
				},
				{
					error: PlcValidationErrorType.maxLen,
					message: this.i18n.nameMaxLen,
					interpolatedParams: {
						name: this.i18n.name,
						max: GameConstants.nameMaxLen,
					},
				},
				{
					error: PlcValidationErrorType.gameExists,
					message: this.i18n.nameExists,
					interpolatedParams: { name: this.i18n.name },
				},
			],
		});

		this.playerOptionsControl = new PlcTypedFormControl([
			PlayersAmount.Two.toString(),
		]);

		this.typeControl = new PlcTypedFormControl([GameType.Public]);

		this.passwordControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(GameConstants.passwordMinSize),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.pswRequired,
					interpolatedParams: { name: this.i18n.password },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.pswMinLen,
					interpolatedParams: {
						name: this.i18n.password,
						min: GameConstants.passwordMinSize,
					},
				},
			],
		});

		this.repeatPasswordControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(GameConstants.passwordMinSize),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.pswRequired,
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.pswMinLen,
				},
				{
					error: PlcValidationErrorType.unmatched,
					message: this.i18n.pswUnmatch,
					interpolatedParams: {
						name: this.i18n.password,
					},
				},
			],
		});

		this._form = new UntypedFormGroup({
			name: this.nameControl,
			playerOptions: this.playerOptionsControl,
			type: this.typeControl,
		});

		this.nameControl.statusChanges.subscribe(() =>
			this._nameFieldStatusChanged$.next(),
		);

		this.typeControl.valueChanges.subscribe(([value]: GameType[]) =>
			this.togglePinFields(value),
		);
	}

	setTranslations(): void {
		this.i18n = {
			name: 'forms.fields.name-prefix',
			password: 'forms.fields.password-prefix',
			nameRequired: 'forms.validations.required',
			nameMinLen: 'forms.validations.minlength',
			nameMaxLen: 'forms.validations.maxlength',
			nameExists: 'forms.validations.already-exists',
			pswRequired: 'forms.validations.required',
			pswMinLen: 'forms.validations.minlength',
			pswUnmatch: 'forms.validations.unmatch',
		};
	}

	public reset(): void {
		this._form.reset(
			{
				playerOptions: [PlayersAmount.Two.toString()],
				type: [GameType.Public],
			},
			{ emitEvent: false },
		);
	}

	/**
	 * @description Method for async validated based control
	 * validation refresh. This way form will not be stuck
	 * in PENDING status after new control adding or visibility
	 * toogle of any of some child control.
	 * @link https://github.com/angular/angular/issues/41519
	 * @todo Remove this method after angular fix this issue since
	 * a BE call is being triggered.
	 */
	public runNameValidations(): void {
		const timeout = setTimeout(() => {
			this.nameControl.updateValueAndValidity();
			clearTimeout(timeout);
		}, 0);
	}

	private togglePinFields(gameType: GameType): void {
		if (!gameType) return;

		if (gameType === GameType.Private) {
			this._form.addControl('password', this.passwordControl);
			this._form.addControl('repeatPassword', this.repeatPasswordControl);
			this._form.setValidators(
				PlcCommonValidators.valuesAreEquals(
					'password',
					'repeatPassword',
				),
			);
		} else {
			this._form.removeControl('password');
			this._form.removeControl('repeatPassword');
			this._form.setValidators([]);
		}

		this.runNameValidations();
	}
}
